body.active__minimal_sidebar .main__content_wrapper .main__content {
    margin-left: 110px !important;
}
body {background: #F5F7F9;}
/* wealthbox section start */
.main__content {width: 100%;}
.wealthbox_section {
    min-height: calc(100vh - 120px);
    margin: 5px 0 20px;
    overflow: hidden;
}
.wealthbox_section .page_heading {
    justify-content: space-between;
    background: #fff;
    flex-wrap: wrap;
    display: flex;
    padding: 20px;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 20px;
}
.wealthbox_section .page_heading h4 {
    font-weight: 500;
    color: #133159;
    margin: 0;
    font-size: 24px;
    line-height: 36px;
}
.wealthbox_section .page_content .contnet_form {min-height: 500px;}
.wealthbox_section .page_title {
    justify-content: space-between;
    align-items: center;
    display: flex;
    width: 100%;
}
.wealthbox_section .page_title h5 {
    color: rgba(19, 49, 89, 0.65);
    font-weight: 500;
    font-size: 20px;
    margin: 0 20px;
    line-height: 30px;
}
.wealthbox_section .page_title a {
    background: #3C7EF3;
    border-radius: 5px;
    line-height: 40px;
    font-weight: 500;
    color: #fff;
    height: 40px;
    width: 85px;
    display: flex;
    font-size: 16px;
    align-items: center;
    justify-content: center;
}
.wealthbox_section .page_title a svg {margin-right: 8px;}
.wealthbox_section .page_content {
    padding: 30px 30px 20px;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
}
.wealthbox_section .page_content h5 {
    color: #133159;
    font-weight: 500;
    font-size: 20px;
    margin: 0;
    line-height: 30px;
}
.wealthbox_section .page_content p {
    color: rgba(19, 49, 89, 0.65);
    font-size: 14px;
    margin-top: 0;
    line-height: 24px;
    margin-bottom: 20px;
}
.wealthbox_section .page_content .btn_group {
    align-items: center;
    display: flex;
    margin-top: 40px;
}
.wealthbox_section .page_content .btn_group .custom_btn svg {margin-right: 5px;}
.wealthbox_section .page_content .btn_group .custom_btn {
    font-family: var(--poppins);
    justify-content: center;
    align-items: center;
    line-height: 50px;
    font-size: 16px;
    display: flex;
    height: 50px;
    width: 135px;
    border: none; 
    cursor: pointer;
    font-weight: 500;
    margin-right: 35px;
    border-radius: 5px;
    background: #F6F7FB;
}
.wealthbox_section .page_content .btn_group .save_btn {
    background: #3C7EF3;
    width: 222px;
    color: #fff;
}
.wealthbox_section .page_content .btn_group .delete_btn {color: #FF264A;}
.wealthbox_section .page_content .btn_group .eidt_btn {color: #3C7EF3;}

.wealthbox_section .page_content .content_footer {
    background: #F6F7FB;
    margin-top: 30px;
    border-radius: 5px;
    padding: 20px 20px 10px;
}
.wealthbox_section .page_content .content_footer p {margin-bottom: 22px;}
.wealthbox_section .page_content .footer_action a {color: #3C7EF3;}
.wealthbox_section .page_content .footer_action strong {
    font-weight: 500;
    color: #133159;
}
.wealthbox_section .page_content .footer_action {margin-bottom: 22px;}
.wealthbox_section .page_content .footer_action p {
    font-size: 16px;
    margin: 0;
}
@media screen and (max-width: 991px) {
    .wealthbox_section .page_content {padding: 30px 20px 20px;}
    .wealthbox_section .page_content h5 {
        line-height: 24px;
        font-size: 16px;
    }
    .wealthbox_section .page_content .footer_action p {font-size: 14px;}
    .wealthbox_section .page_content .content_footer .sm_font {font-size: 12px;}
}
/* wealthbox section end */