body.active__minimal_sidebar .main__content_wrapper .main__content {
    margin-left: 110px !important;
}
body {background: #F5F7F9;}
/* redtail section start */
.main__content {width: 100%;}
.app_integration_form_section {
    min-height: calc(100vh - 120px);
    margin: 5px 0 20px;
    overflow: hidden;
}
.app_integration_form_section .page_heading {
    justify-content: space-between;
    background: #fff;
    flex-wrap: wrap;
    display: flex;
    padding: 20px;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 20px;
}
.app_integration_form_section .page_heading h4 {
    font-weight: 500;
    color: #133159;
    margin: 0;
    font-size: 24px;
    line-height: 36px;
}
.app_integration_form_section .page_title {
    justify-content: space-between;
    align-items: center;
    display: flex;
    width: 100%;
}
.app_integration_form_section .page_title h5 {
    color: rgba(19, 49, 89, 0.65);
    font-weight: 500;
    font-size: 20px;
    margin: 0 20px;
    line-height: 30px;
}
.app_integration_form_section .page_title a {
    background: #3C7EF3;
    border-radius: 5px;
    line-height: 40px;
    font-weight: 500;
    color: #fff;
    height: 40px;
    width: 85px;
    display: flex;
    font-size: 16px;
    align-items: center;
    justify-content: center;
}
.app_integration_form_section .page_title a svg {margin-right: 8px;}
.app_integration_form_section .page_content {
    padding: 30px 30px 20px;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
}
.app_integration_form_section .page_content h5 {
    color: #133159;
    font-weight: 500;
    font-size: 20px;
    margin: 0;
    line-height: 30px;
}
.app_integration_form_section .page_content p {
    color: rgba(19, 49, 89, 0.65);
    font-size: 16px;
    margin-top: 0;
    line-height: 24px;
}
.app_integration_form_section .page_content p.sm_font {
    font-size: 14px;
    line-height: 21px;
}
.app_integration_form_section .page_content .input_group {
    max-width: 777px;
    width: 100%;
    margin-bottom: 20px;
}
.app_integration_form_section .page_content .input_group label {
    margin-bottom: 8px;
    color: #133159;
    font-size: 16px;
    display: block;
    line-height: 22px;
}
.app_integration_form_section .page_content .input_group input:focus {
    border: 1px solid #ADB7C5 !important;
    box-shadow: none !important;
}
.app_integration_form_section .page_content .input_group input::placeholder {color: #ADB7C5;}
/* .app_integration_form_section .page_content .input_group input[value] {background: #E5E8EF;} */
.app_integration_form_section .page_content .input_group input {
    /* color: rgba(19, 49, 89, 0.65); */
    font-family: var(--poppins);
    border: 1px solid #ADB7C5;
    padding: 0 12px;
    width: 100%;
    height: 50px;
    margin: 0;
    font-size: 16px;
    box-shadow: none;
    border-radius: 5px;
    background: #fff;
    box-sizing: border-box;
}
.app_integration_form_section .page_content .btn_group {
    align-items: center;
    display: flex;
    margin-top: 40px;
}
.app_integration_form_section .page_content .btn_group .custom_btn svg {margin-right: 5px;}
.app_integration_form_section .page_content .btn_group .custom_btn svg.MuiCircularProgress-svg {margin-right: 0;}
.app_integration_form_section .page_content .btn_group .custom_btn {
    font-family: var(--poppins);
    justify-content: center;
    align-items: center;
    line-height: 50px;
    font-size: 16px;
    display: flex;
    height: 50px;
    min-width: 135px;
    border: none; 
    cursor: pointer;
    font-weight: 500;
    margin-right: 35px;
    border-radius: 5px;
    background: #F6F7FB;
}
.app_integration_form_section .page_content .btn_group .save_btn {
    background: #3C7EF3;
    width: 222px;
    color: #fff;
}
.app_integration_form_section .page_content .btn_group .eidt_btn {color: #3C7EF3;}
.app_integration_form_section .page_content .btn_group .delete_btn {
    color: #FF264A;
    width: 157px;
}

.app_integration_form_section .page_content .content_footer {
    background: #F6F7FB;
    margin-top: 30px;
    border-radius: 5px;
    padding: 20px 20px 10px;
}
.app_integration_form_section .page_content .content_footer p {margin-bottom: 22px;}
.app_integration_form_section .page_content .footer_action a {color: #3C7EF3;}
.app_integration_form_section .page_content .footer_action strong {
    font-weight: 500;
    color: #133159;
}
.app_integration_form_section .page_content .footer_action {margin-bottom: 22px;}
.app_integration_form_section .page_content .footer_action p {
    font-size: 16px;
    margin: 0;
    line-height: 22px;
}

.input_group .readable_edit_input {
    border: 1px solid #bbb;
    border-radius: 5px;
    padding: 12px;
    background: #E5E8EF;
    color: rgba(19, 49, 89, 0.65);
}

@media screen and (max-width: 991px) {
    .app_integration_form_section .page_content {padding: 30px 20px 20px;}
    .app_integration_form_section .page_content h5 {
        line-height: 24px;
        font-size: 16px;
    }
	.app_integration_form_section .page_content .footer_action p,
	.app_integration_form_section .page_content p {
		line-height: 21px;
		font-size: 14px;
	}
    .app_integration_form_section .page_content .content_footer .sm_font {font-size: 12px;}
}
/* redtail section end */


/* redtail new design start */
.app_store_module__preview_field {
    position: relative;
}

.app_store_module__preview_field .password_visible svg {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 14px;
    top: 14px;
    cursor: pointer;
}
/* redtail new design end */