body.active__minimal_sidebar .main__content_wrapper .main__content {
    margin-left: 110px !important;
}
body {background: #F5F7F9;}
/* facebook section start */
.main__content {width: 100%;}
.facebook_section {
    /* min-height: calc(100vh - 120px); */
    margin: 5px 0 20px;
    /* overflow: hidden; */
}
.facebook_section .page_heading {
    
}
.facebook_section .page_heading .title h4 {
	font-weight: 500;
    color: #133159;
    margin: 0;
    font-size: 24px;
    line-height: 36px;
}
.facebook_section .page_heading .title a {
	line-height: 36px;
	font-size: 24px;
    margin: 0;
	font-weight: 500;
}
.facebook_section .page_heading .title img {
	margin-right: 10px;
	width: 48px;
	height: 48px;
	border-radius: 50%;
}
.facebook_section .page_heading .title svg {margin-left: 20px;}
.facebook_section .page_heading .title a {
    margin-left: 10px;
    color: #3C7EF3;
}
.facebook_section .page_heading .title a,
.facebook_section .page_heading .title {
    align-items: center;
    display: flex;
}
.facebook_section .page_heading .form_group.search {
    border: 1px solid rgba(19, 49, 89, 0.35);
    border-radius: 5px;
    width: 320px;
    height: 50px;
    display: flex;
    align-items: center;
}
.facebook_section .page_heading .form_group.search input::placeholder {color: rgba(19, 49, 89, 0.35);}
.facebook_section .page_heading .form_group.search input {
    font-family: var(--poppins);
    padding: 0 0 0 10px;
    box-shadow: none;
    outline: none;
    border: none;
    margin: 0;
    height: 100%;
    font-size: 16px;
    color: #133159;
}
.facebook_section .page_heading .form_group.search button svg {
    margin-top: 2px;
    width: 18px;
    height: 18px;
}
.facebook_section .page_heading .form_group.search button {
    padding-right: 12px;
    background: none;
    cursor: pointer;
    width: 42px;
    border: none;
    outline: none;
}
.facebook_section .page_heading .header_action {
	/* align-items: center;
	display: flex;
	justify-content: flex-end; */
}
.facebook_section .page_heading .header_action a {
    background: #3C7EF3;
    border-radius: 5px;
    margin-left: 35px;
    line-height: 50px;
    font-weight: 500;
    color: #fff;
    height: 37px;
    width: 90px;
    display: flex;
    font-size: 15px;
    min-width: 100px;
    align-items: center;
    justify-content: center;
}
.facebook_section .page_heading .header_action a svg {margin-right: 8px;}
.facebook_section .page_content {margin-top: 20px;}

.main__content_wrapper {overflow: auto;}
.facebook_section .page_content {
    /* height: calc(100vh - 215px);
    overflow-y: scroll;
    overflow-x: hidden; */
    padding-right: 12px;
    margin-right: -20px;
}
.facebook_section .page_content > .row .custom_grid_5,
.facebook_section .page_content > .row .custom_grid_6 {padding: 0 15px;}
.facebook_section .page_content > .row {
    justify-content: space-between;
    margin-bottom: 30px;
    align-items: center;
    margin-left: -15px;
    display: flex;
    margin-right: -15px;
}
.facebook_section .page_content > .row .custom_grid_6 .card_body {
    align-items: flex-start;
    padding: 12px 0 0;
}
.facebook_section .page_content > .row .custom_grid_6 {
    max-width: 50%;
    width: 100%;
}
.facebook_section .page_content > .row .custom_grid_5 {
    max-width: 50%;
    width: 100%;
}
.facebook_section .page_content .option_select.sm {max-width: 204px;}
.facebook_section .page_content .option_select {max-width: 248px;}
.facebook_section .page_content .content_card {
    border-radius: 10px;
    background: #fff;
    padding: 22px 20px;
}
.facebook_section .page_content .card_header {
    border-bottom: 1px solid rgba(19, 49, 89, 0.1);
    padding: 0 0 20px;
    display: flex;
    justify-content: space-between;
}
.facebook_section .page_content .card_header .title h6 {color: #133159;}
.facebook_section .page_content .card_header .title h6,
.facebook_section .page_content .card_header .title a {
    font-size: 16px;
    margin: 0;
}
.facebook_section .page_content .card_header .title img {margin-right: 10px;}
.facebook_section .page_content .card_header .title a {
    margin-left: 10px;
    color: #3C7EF3;
}
.facebook_section .page_content .card_header .title a,
.facebook_section .page_content .card_header .title {
    align-items: center;
    display: flex;
}
.facebook_section .page_content .card_body {
    justify-content: space-between;
    padding: 30px 0 10px;
    display: flex;
    align-items: center;
}
.facebook_section .page_content .card_body a {
    line-height: 24px;
    color: #3C7EF3;
    font-size: 16px;
}
.facebook_section .page_content .card_body p {
    text-transform: uppercase;
    color: #27AE60;
    margin: 0;
    font-size: 16px;
    line-height: 24px;
}
.facebook_section .page_content .custom_btn a {
    color: #3C7EF3;
    font-size: 16px;
    line-height: 24px;
}
.facebook_section .page_content .custom_btn,
.facebook_section .page_content .custom_btn:focus {
    font-family: var(--poppins);
    line-height: 38px;
    font-size: 16px;
    color: #fff;
    height: 38px;
    width: 110px;
    border: none;
    cursor: pointer;
    font-weight: 500;
    border-radius: 5px;
    background: #3C7EF3;
    padding: 5px, 10px, 5px, 10px;
}

.facebook_section .page_content .custom_btn:focus {
    background: #3C7EF3 !important;
}

.facebook_section .page_content .switch {
    display: inline-block;
    position: relative;
    width: 62px;
    height: 32px;
    cursor: pointer;
}
.facebook_section .page_content .switch [type="checkbox"]:checked + span + small {display: none;}
.facebook_section .page_content .switch input { 
  	opacity: 0;
  	width: 0;
  	height: 0;
}
.facebook_section .page_content .switch .slider {
    position: absolute;
    transition: .4s;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
}
.facebook_section .page_content .switch .slider:before {display: none;}
.facebook_section .page_content .switch .slider.round {
    border-radius: 34px;
    height: 30px;
    background: none;
}
.facebook_section .page_content .switch .slider.round:before {
    border-radius: 50%;
    border: none;
    top: 1px;
    left: 5px;
    width: 22px;
    z-index: 99;
    height: 22px;
    background: #9CA8BA;
}
.facebook_section .page_content .switch .on {
    color: #fff;
    left: 4px;
}
.facebook_section .page_content .switch .on,
.facebook_section .page_content .switch .off {
    position: absolute;
    height: 32px;
    top: 0;
    left: 0;
    z-index: 9;
    width: 60px;
}
.facebook_section .page_content .switch .off {
    color: #9CA8BA;
    right: 5px;
}
.facebook_section .page_content .card_header h5 span {color: #3C7EF3;}
.facebook_section .page_content .card_header h5 {
    font-weight: 500;
    font-size: 16px;
    margin: 0;
    line-height: 24px;
}
.facebook_section .page_content .option_select {
    position: relative;
    max-width: 248px;
    width: 100%;
    margin-right: 10px;
}
.facebook_section .page_content .input_group label {
    margin-bottom: 5px;
    color: #133159;
    font-size: 16px;
    display: block;
    line-height: 24px;
}
.facebook_section .page_content .input_group input {
    color: rgba(19, 49, 89, 0.65);
    border: 1px solid #E5E8EF;
    padding: 0 12px;
    width: 100%;
    height: 38px;
    margin: 0;
    border-radius: 5px;
    font-size: 16px;
    background: #F6F7FB;
    box-sizing: border-box;
}
.facebook_section .page_content .input_group select {
    border: 1px solid rgba(29, 35, 43, 0.35);
    color: rgba(19, 49, 89, 0.65);
    font-size: 16px;
    width: 100%;
    border-radius: 5px;
}
.facebook_section .page_content .input_group svg {
    position: absolute;
    z-index: 9;
    right: 8px;
    top: 10px;
    width: 13px;
    height: 25px;
    background: #F6F7FB;
}
.facebook_section .page_content .all_result {text-align: center;}
.facebook_section .page_content .all_result span {
    line-height: 24px;
    font-size: 16px;
}
.facebook_section .page_content .all_result h4 {
    line-height: 24px;
    font-weight: 500;
    font-size: 32px;
    margin-bottom: 0;
    margin-top: 18px;
}
.facebook_section .page_content .all_result span,
.facebook_section .page_content .all_result h4 {
    color: #133159;
}

.subscribe__facebook_button { 
    width: 260px !important;
    height: 55px !important;
    margin-left: 12px;
}
.page_content .MuiPaper-root{
    max-width: unset;
}

.facebook_section .page_content > .MuiPaper-root {
    display: block;
}


/* ===Facebook page css=== */
.facebook_page_subscriber h6{
    font-family: var(--poppins);
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
}
.facebook_page_subscriber h4{
    font-family: var(--poppins);
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 30px;
    margin: 0;
}
.facebook_select input{
    border-bottom: unset !important;
    padding: 5px 14px !important;
}
.facebook_select input:focus{
    box-shadow: unset !important;
    border-bottom: unset !important;
}
.facebook_select .MuiInputBase-root:focus-visible{
    border-color: white !important;
}
.f_page_details img{
    width: 70px;
    height: 70px;
    border-radius: 10px;
    margin-right: 20px;
}
.f_page_card{
    flex-wrap: wrap;
}
.f_page_details{
    background: #F6F7FB;
    max-width: 290px;
    padding: 10px 15px;
    border-radius: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}
.f_page_name h6{
    font-size: 14px;
    font-weight: normal;
}
.cross_icon{
    cursor: pointer;
}

.map_field__modal_title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}

.globalModalWrapper .customFormControlFb.alt {
    margin-top: 0!important;
    width: 100%;
}

.customFormControlFb.alt .map_field__modal_select .MuiSelect-select {
    border: 1px solid #ccd2d2!important;
    padding: 10px;
    border-radius: 4px;
}

.map_field__modal_select::before {
    border-bottom: unset !important;
}

.map_field__modal_select::after {
    border-bottom: unset !important;
}

.map_field__modal_select {
    margin: 0!important;
    padding: 0!important;
    box-shadow: unset!important;
    width: 100%!important;
    height: 50px!important;
}

.cursor-pointer {
    cursor: pointer;
}

@media screen and (max-width: 1500px) {
    .facebook_section .page_content {
        height: calc(100vh - 285px);
    }
    .facebook_section .page_heading .form_group.search {width: 270px;}
    .facebook_section .page_heading .form_group.search {width: 269px;}
    .facebook_section .page_heading .header_action a {margin-left: 0px;}
    .facebook_section .page_content .option_select.sm {max-width: 190px;}
    .facebook_section .page_content .option_select {max-width: 234px;}

    .facebook_section .page_content > .row .custom_grid_6 {
        max-width: 620px !important;
        width: 100%;
    }
    .facebook_section .page_content > .row .custom_grid_5 {
        max-width: 500px !important;
        width: 100%;
    }
}
@media screen and (max-width:1360px) {
    .facebook_section .page_content > .row .custom_grid_5 {margin-bottom: 20px;}
    .facebook_section .page_content > .row .custom_grid_6 {margin-top: 20px;}
    .facebook_section .page_content > .row .custom_grid_5,
    .facebook_section .page_content > .row .custom_grid_6 {
        max-width: 100% !important;
        padding: 0 10px;
        width: 100%;
    }
    .facebook_section .page_heading .header_action {
        margin-top: 20px;
        width: 100%;
    }
    .facebook_section .page_content > .row > svg {
        transform: rotate(90deg);
        width: 14px;
    }
    .facebook_section .page_content > .row {
        margin-right: -10px;
        margin-left: -10px;
        flex-wrap: wrap;
        justify-content: center;
    }
    .facebook_section .page_heading {flex-wrap: wrap;}
}
/* facebook section end */