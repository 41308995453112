body.active__minimal_sidebar .main__content_wrapper .main__content {
    margin-left: 110px !important;
}
body {background: #F5F7F9;}
/* integration section start */
.main__content {width: 100%;}
.integration_section {
    min-height: calc(100vh - 120px);
    margin: 5px 0 20px;
    overflow: hidden;
}
.integration_section .page_heading {
    justify-content: space-between;
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 20px;
}
.integration_section .page_heading h4 {
    font-weight: 500;
    color: #133159;
    margin: 0;
    font-size: 24px;
    line-height: 36px;
}
.integration_section .page_content {margin-top: 20px;}
.integration_section .page_content > .row .col {padding: 0 15px;}
.integration_section .page_content > .row {
    margin-left: -15px;
    margin-bottom: 0;
    margin-right: -15px;
}
.integration_section .page_title {
    color: rgba(19, 49, 89, 0.65);
    font-weight: 500;
    font-size: 20px;
    margin: 0 20px;
    line-height: 30px;
}
.integration_section .integration_card {
    border-radius: 10px;
    background: #fff;
    padding: 20px;
    margin-bottom: 20px;
}
.integration_section .integration_card .card_body {
    text-align: center;
}
.integration_section .integration_card .card_body h6 {
    font-weight: 500;
    color: #6258FF;
    margin: 0;
    font-size: 20px;
    line-height: 30px;
}
.integration_section .integration_card .card_body p {
    color: rgba(19, 49, 89, 0.65);
    font-size: 16px;
    line-height: 24px;
    margin: 2px 0 20px;
}
.integration_section .integration_card .card_body p.sm {font-size: 14px;}
.integration_section .integration_card .card_body .change {color: rgba(19, 49, 89, 0.65) !important;}
.integration_section .integration_card .card_body .custom_btn svg {margin-right: 5px;}
.integration_section .integration_card .card_body .custom_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #3C7EF3;
    border: none;
    width: 100%;
    height: 50px;
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 50px;
    padding: 0 25px;
    border-radius: 5px;
}
.integration_section .integration_card .profile {
    align-items: center;
    font-size: 16px;
    display: flex;
    max-width: 400px;
    margin: 20px auto 12px;
}
.integration_section .integration_card .profile img {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin-right: 10px;
}
.integration_section .integration_card .profile h6 {
    color: rgba(19, 49, 89, 0.65);
    font-size: 16px;
    margin: 0;
}
.integration_section .integration_card .profile a {
    align-items: center;
    color: #3C7EF3;
    font-size: 16px;
    display: flex;
    margin-left: 10px;
}
.integration_section .integration_card .card_footer {
    border-top: 1px solid #E5E8EF;
    padding: 15px 0 10px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.integration_section .integration_card .card_footer p {
    line-height: 24px;
    font-size: 16px;
    margin: 0;
    color: rgba(19, 49, 89, 0.65);
}
.integration_section .integration_card .card_footer p a {color: #3C7EF3;}
.integration_section .integration_card .card_footer .setting_btn {
    justify-content: center;
    background: #F6F7FB;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    width: 102px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    border-radius: 5px;
    color: rgba(19, 49, 89, 0.65);
}
.integration_section .integration_card .setting_btn svg {
    margin-right: 5px;
    margin-top: -2px;
}

.integration_card .card_body img {
    height: 80px;
    object-fit: cover;
}

@media screen and (max-width: 1500px) {
    .integration_section .integration_card .profile {
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;
    }
}
@media screen and (max-width: 991px) {
    .integration_section .page_content > .row .col {padding: 0 10px;}
    .integration_section .page_content > .row {
        margin-left: -10px;
        margin-right: -10px;
    }
    .integration_section .integration_card .card_body .custom_btn {
        line-height: 40px;
        height: 40px;
    }
    .integration_section .integration_card .card_footer .setting_btn {
        line-height: 36px;
        width: 80px;
        height: 36px;
        font-size: 14px;
    }
    .integration_section .integration_card .card_footer .setting_btn svg {
        height: 13px;
        width: 13px;
    }
}
/* integration section end */