.container {
    line-height: 100vh;
    height: 100vh;
    text-align: center;
}

.container .loader {
    display: inline-block;
    vertical-align: middle;
}

