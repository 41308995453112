.invoice-class .notfound-page-root {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.invoice-class .notfound-page-root .notfound {
  max-width: 410px;
  width: 100%;
  text-align: center;
}

.invoice-class .notfound-page-root .notfound .notfound-404 {
  height: 280px;
  position: relative;
  z-index: -1;
}

.invoice-class .notfound-page-root .notfound .notfound-404 h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 230px;
  margin: 0px;
  font-weight: 900;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  background: url('https://d24gujj67p1uc1.cloudfront.net/asset/images/releases/invoice/1.0.0/invoice-fail.jpg') no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: center;
}


.invoice-class .notfound-page-root .notfound h2 {
  font-family: 'Montserrat', sans-serif;
  color: #000;
  font-size: 24px;
  font-weight: 700;
  /* text-transform: uppercase; */
  margin-top: 0;
}


@media only screen and (max-width: 767px) {
  .invoice-class .notfound-page-root .notfound .notfound-404 {
    height: 142px;
  }

  .invoice-class .notfound-page-root .notfound .notfound-404 h1 {
    font-size: 112px;
  }
}