.facebook_callback_wrapper {
    background: #eee;
    padding: 20px;
    width: 80%;
    margin: auto;
    border: 1px solid #e6e6e6;
    border-radius: 9px;
    margin-top: 30px;
}

.facebook_callback_wrapper h3 {
    font-size: 20px;
}